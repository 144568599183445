<div class="sidebar1">
  <div class="logo" align="center">
    <a [routerLink]="['/overview']">
      <div class="borrowersolarLogo" style="margin-bottom: 0px"></div
    ></a>
  </div>
  <div class="menulist">
    <div
      class="displayFlex ptb10 cursorpointer"
      [routerLinkActive]="['bg-borrower']"
      [routerLink]="['/overview']"
      [routerLinkActive]="['MenuActive']"
    >
      <div class="iconBlock">
        <div class="homeIcon"></div>
      </div>
      <div>Overview</div>
    </div>

    <!-- <div
      class="displayFlex ptb10 cursorpointer"
      [routerLinkActive]="['bg-borrower']"
      [routerLink]="['/todo-list']"
      [routerLinkActive]="['MenuActive']"
    >
      <div class="iconBlock">
        <div class="todoIcon"></div>
      </div>
      <div>To-do list</div>
    </div> -->

    <div
      class="displayFlex ptb10 cursorpointer"
      [routerLinkActive]="['bg-borrower']"
      [routerLink]="['/payment-schedule']"
      [routerLinkActive]="['MenuActive']"
    >
      <div class="iconBlock">
        <div class="paymentIcon"></div>
      </div>
      <div>Payment Schedule</div>
    </div>

    <div
      class="displayFlex ptb10 cursorpointer"
      [routerLinkActive]="['bg-borrower']"
      [routerLink]="['/make-payment']"
      [routerLinkActive]="['MenuActive']"
    >
      <div class="iconBlock">
        <div class="makepaymentIcon"></div>
      </div>
      <div>Make a payment</div>
    </div>
    <div
      class="displayFlex ptb10 cursorpointer"
      [routerLinkActive]="['bg-borrower']"
      [routerLink]="['/payment-method']"
      [routerLinkActive]="['MenuActive']"
    >
      <div class="iconBlock">
        <div class="paymentmethodIcon"></div>
      </div>
      <div>Payment Method</div>
    </div>
    <div
      class="displayFlex ptb10 cursorpointer"
      [routerLinkActive]="['bg-borrower']"
      [routerLink]="['/docs-management']"
      [routerLinkActive]="['MenuActive']"
    >
      <div class="iconBlock">
        <div class="documentIcon"></div>
      </div>
      <div>Documents Management</div>
    </div>

    <div
      class="displayFlex ptb10 cursorpointer"
      [routerLinkActive]="['bg-borrower']"
      [routerLink]="['/settings']"
      [routerLinkActive]="['MenuActive']"
    >
      <div class="iconBlock">
        <div class="setting"></div>
      </div>
      <div>Settings</div>
    </div>

    <div
      class="displayFlex ptb10 cursorpointer"
      [routerLinkActive]="['bg-borrower']"
      [routerLinkActive]="['MenuActive']"
      (click)="logout()"
    >
      <div class="iconBlock">
        <div class="logoutIcon"></div>
      </div>
      <div>Logout</div>
    </div>
    <!--
    <div
      [routerLinkActive]="['bg-borrower']"
      [routerLink]="['/overview']"
    >
      <a class="btn"
        ><i class="fa fa-home" aria-hidden="true"></i
        >&nbsp;&nbsp;&nbsp;Overview</a
      >
    </div>
    <div>
      <a class="btn"
        ><i class="fa fa-list-alt" aria-hidden="true"></i
        >&nbsp;&nbsp;&nbsp;To-do list</a
      >
    </div>
    <div
      [routerLinkActive]="['bg-borrower']"
      [routerLink]="['/payment-schedule']"
    >
      <a class="btn"
        ><i class="fa fa-calendar-check-o" aria-hidden="true"></i>
        &nbsp;&nbsp;&nbsp;Payment Schedule</a
      >
    </div>
    <div
      [routerLinkActive]="['bg-borrower']"
      [routerLink]="['/make-payment']"
    >
      <a class="btn"
        ><i class="fa fa-money" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Make a
        payment</a
      >
    </div>
    <div
      [routerLinkActive]="['bg-borrower']"
      [routerLink]="['/payment-method']"
    >
      <a class="btn"
        ><i class="fa fa-credit-card-alt" aria-hidden="true"></i>
        &nbsp;&nbsp;&nbsp;Payment Method</a
      >
    </div>
    <div
      [routerLinkActive]="['bg-borrower']"
      [routerLink]="['/docs-management']"
    >
      <a class="btn"
        ><i class="fa fa-file-text" aria-hidden="true"></i>
        &nbsp;&nbsp;&nbsp;Documents Management</a
      >
    </div> -->
  </div>
</div>
