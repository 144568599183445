import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
    
    // { path: "sales", loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule)},
    // { path: "installer", loadChildren: () => import('./installer/installer.module').then(m => m.InstallerModule)},
    { path: "", loadChildren: () => import('./borrower/borrower.module').then(m => m.BorrowerModule)},
    // { path: "", loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},
  	// {path: '', redirectTo: "admin", pathMatch: 'full'},
    {path: '**', redirectTo: "", pathMatch: 'full'},
  ];

@NgModule({
  //imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled',useHash: true, relativeLinkResolution: "legacy", })],
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
