import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  salesapiurl = '';
  adminapiurl = '';
  borrowerapiurl = ''
  installerapiurl = ''
  de = ''
  public isExpandMenuObject: BehaviorSubject<any>;
  public isExpandMenu: Observable<boolean>;
  constructor(
    private http:HttpClient,
    configService: ConfigService,
    private spinner: NgxSpinnerService
  ) {
    this.salesapiurl = configService.config.salesapiurl;
    this.adminapiurl = configService.config.adminapiurl;
    this.borrowerapiurl = configService.config.borrowerapiurl;
    this.installerapiurl = configService.config.installerapiurl;
    this.de = configService.config.de;
    this.isExpandMenuObject = new BehaviorSubject<boolean>(true);
    this.isExpandMenu = this.isExpandMenuObject.asObservable();
  }
  geturl(url,key){
    let nurl = ""
    switch (key) {
      case 'sales':
        nurl = this.salesapiurl+url
        break;
      case 'admin':
        nurl = this.adminapiurl+url
        break;
      case 'borrower':
        nurl = this.borrowerapiurl+url
        break;
      case 'installer':
        nurl = this.installerapiurl+url
        break;
      case 'de':
        nurl = this.de+url
        break;
    }
    return nurl;
  }


  gettoken(key){
    let token = ""
    switch (key) {
      case 'admin':
        token =  "Bearer "+sessionStorage.getItem('admin_token')
        break;
      case 'borrower':
        token =  "Bearer "+sessionStorage.getItem('borrower_token')
        break;
      case 'installer':
        token =  "Bearer "+sessionStorage.getItem('installer_token')
        break;
    }
    return token;
  }

  get(url,key){
    let httpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin','*')
    let options = {
        headers: httpHeaders
    };
    let nurl = this.geturl(url,key);

     return this.http.get(nurl,options)
  }

  post(url,key,data){
    let httpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin','*')
    let options = {
        headers: httpHeaders
    };
    let nurl = this.geturl(url,key);
     return this.http.post(nurl,data,options)
  }

  files(url,key,data){
    let httpHeaders = new HttpHeaders()
    .set('dataType', 'jsonp')
    .set('Access-Control-Allow-Origin','*')
    let options = {
      headers: httpHeaders
  };
    let nurl = this.geturl(url,key);
    return this.http.post(nurl,data,options)
  }

  authfiles(url,key,data){

    let httpHeaders = new HttpHeaders()
    .set('dataType', 'jsonp')
    .set('Access-Control-Allow-Origin','*')
    .set('Authorization', this.gettoken(key))
    let options = {
      headers: httpHeaders
  };
    let nurl = this.geturl(url,key);
    return this.http.post(nurl,data,options)
  }

  authget(url,key){
    let httpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin','*')
    .set('Authorization', this.gettoken(key))
    let options = {
        headers: httpHeaders
    };
    let nurl = this.geturl(url,key);
     return this.http.get(nurl,options)
  }

  authpost(url,key,data){
    let httpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin','*')
    .set('Authorization', this.gettoken(key))
    let options = {
        headers: httpHeaders
    };
    let nurl = this.geturl(url,key);
     return this.http.post(nurl,data,options)
  }





authput(url,key,data){​​​​​​​​
let httpHeaders = new HttpHeaders()
 .set('Content-Type', 'application/json')
 .set('Access-Control-Allow-Origin','*')
 .set('Authorization', this.gettoken(key))
let options = {​​​​​​​​
headers: httpHeaders
 }​​​​​​​​;
let nurl = this.geturl(url,key);
return this.http.put(nurl,data,options)
 }​​​​​​​​


 logout(){
   sessionStorage.clear()
   location.reload();
 }

 addlog(module,loan_id,key){
  let httpHeaders = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin','*')
  .set('Authorization', this.gettoken(key))
  let options = {
      headers: httpHeaders
  };
  let data = {
    module:module,
    loan_id:loan_id
  }
  let nurl = ""
  switch(key){
    case "admin":
      data['user_id']=JSON.parse(sessionStorage.getItem('resuser'))['id']
      nurl = this.geturl("pending/addlogs",key);
      return this.http.post(nurl,data,options)
    break;
    case "borrower":
      data['user_id']=sessionStorage.getItem('UserId')
      nurl = this.geturl("overview/addlogs",key);
      return this.http.post(nurl,data,options)
    break;
    case "installer":
      data['user_id']=sessionStorage.getItem('InstallerUserId')
      nurl = this.geturl("logs/addlogs",key);
      return this.http.post(nurl,data,options)
    break;
  }
 }

 addLoginLog(key){
  let httpHeaders = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin','*')
  .set('Authorization', this.gettoken(key))
  let options = {
      headers: httpHeaders
  };
  let data = {
  }
  let nurl = ""
  switch(key){
    case "admin":
      data['user_id']=JSON.parse(sessionStorage.getItem('resuser'))['id']
      nurl = this.geturl("pending/addloginlogs",key);
      return this.http.post(nurl,data,options)
    break;
    case "borrower":
      data['user_id']=sessionStorage.getItem('UserId')
      nurl = this.geturl("overview/addloginlogs",key);
      return this.http.post(nurl,data,options)
    break;
    case "installer":
      data['user_id']=sessionStorage.getItem('InstallerUserId')
      nurl = this.geturl("logs/addloginlogs",key);
      return this.http.post(nurl,data,options)
    break;
  }
 }

 authdelete(url,key){
  let httpHeaders = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin','*')
  .set('Authorization', this.gettoken(key))
  let options = {
  headers: httpHeaders
  };
  let nurl = this.geturl(url,key);
  return this.http.delete(nurl,options)
  }

  download(url,key): Observable<Blob>{

    let httpHeaders = new HttpHeaders()
    .set('dataType', 'jsonp')
    .set('Access-Control-Allow-Origin','*')
    .set('Authorization', this.gettoken(key))
    let nurl = this.geturl(url,key);
    return this.http.get(nurl,{headers:httpHeaders,responseType:'blob'})
  }

  pdf(data){
    
  }
  menuHideAndShow(status: boolean) {
    this.isExpandMenuObject.next(status);
  }

}
