import { HttpClient } from '@angular/common/http';
import { Injectable, APP_INITIALIZER } from '@angular/core';
import { Config } from 'protractor';

import { environment } from '../../environments/environment'; //path to your environment files

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    config: Config;
    private _env: string;
  
    constructor(private http: HttpClient) {}
  
    loadConfig() {
        this._env = 'development';
        if (environment.production)
            this._env = 'production';
        console.log(this._env)

        return this.http
            .get<Config>('../../assets/config/' + this._env + '.json')
            .toPromise()
            .then(config => {
                this.config = config;
            });
    }
}

export function ConfigFactory(config: ConfigService) {
    return () => config.loadConfig();
}

export function init() {
    return {
        provide: APP_INITIALIZER,
        useFactory: ConfigFactory,
        deps: [ConfigService],
        multi: true
    }
}

const ConfigModule = {
    init: init
}

export { ConfigModule };
