import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';

import { CurrencyPipe } from '@angular/common';
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import { gridFill,person,cardText } from 'ngx-bootstrap-icons';
import { NgxSpinnerModule } from "ngx-spinner";
import {HttpService} from './_service/http.service';
import {JwtInterceptor} from './_service/jwt.interceptor';
import { BorrowerSidebarComponent } from './borrower-sidebar/borrower-sidebar.component';
import { DatePipe,DecimalPipe } from '@angular/common';
import { ConfigModule, ConfigService } from 'src/app/config/config.service';



const icons = {
  gridFill,
  person,
  cardText
};




@NgModule({
  declarations: [
    AppComponent,
    BorrowerSidebarComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgxBootstrapIconsModule.pick(icons),
    HttpClientModule,
    NgxSpinnerModule
    
  ],
  providers: [
    CurrencyPipe,DatePipe,DecimalPipe,HttpService,ConfigService,ConfigModule.init(),
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: JwtInterceptor, 
      multi: true 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
